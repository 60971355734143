import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [" - 스테이지파이브, 카카오페이 인증 활용한 비대면 통신가입시스템 구축"]

const content = () => (
  <div>
    <style>
      {
        "\
        .pressDetailPage .pressDetailBody .contentArea .point {\
            color: #70418F;\
          }\
        .pressDetailPage .pressDetailBody .contentArea .descImage {\
          display: table;\
          width: 100%;\
        }\
        .pressDetailPage .pressDetailBody .contentArea .descImage div {\
          display: table-cell;\
          vertical-align: bottom;\
        }\
        "
      }
    </style>
    <p>
      <br />
      ㅣ 카카오의 손자회사이자 통신계열사인 스테이지파이브는 지난 6월 30일(화), 카카오페이 인증을 활용한 비대면 통신가입서비스로
      ICT규제샌드박스를 통과, 이를 계기로 본격적인 신개념 통신사업을 8월 런칭 준비 중에 있다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 이번 규제샌드박스 사업은 비대면 휴대폰 가입 UX에서 중요한 요소였던 개인인증과정에 카카오페이를 활용, 그 절차를 간소화
      하였다는데 큰 의의가 있다. 해당 내용을 골자로,{" "}
      <span className="bold">
        스테이지파이브는 8월 중 자사 다이렉트몰을 오픈하여 고객들에게 보다 '안전'하고 '간편'하며 '투명'한 통신가치를 제공할
        예정이다. 해당 몰이 오픈되면 카카오톡에서도 휴대폰을 가입하고 개통할 수 있는 시대가 열리게 된다.
      </span>
      <br />
    </p>
    <br />
    <p>
      ㅣ 스테이지파이브는 일반 단말이나 요금제 뿐만 아니라 고객 라이프스타일과 니즈에 따라 키즈폰, 소셜로봇, 미러북 등 통신과
      결합된 특화 디바이스도 준비 중에 있다.{" "}
      <span className="point bold">
        이를 바탕으로 생활밀착형 통신가치를 제공하여 포스트코로나시대의 비대면경제를 견인하고, 키즈, 시니어, 교육, 오피스
        시장으로의 새로운 도약의 계기가 될 것으로 기대하고 있다.
      </span>
      <br />
    </p>
    <br />
    <p>
      <span className="bold">
        ㅣ 나아가, 스테이지파이브는 기존 통신의 영역을 넘어 ICT 융합형 사업을 전개하여 새로운 가치를 창출하고 우리 삶 전반에 걸쳐
        디지털혁신을 체감할 수 있도록 지속적인 노력을 기울일 예정이다.
      </span>
      <br />
    </p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <div className="desc">[사진1 _ 카카오톡 내 자사 다이렉트몰 UI(안)]</div>
    <br />
    <div className="descImage directmall">
      <div>
        <img src="/images/press/2/BZvtvAXj.jpg" alt="구매" />
      </div>
      <div style={{ width: 15 + "px" }}></div>
      <div>
        <img src="/images/press/2/uNxDkCXK.jpg" alt="인증" />
      </div>
      <div style={{ width: 15 + "px" }}></div>
      <div>
        <img src="/images/press/2/qUmxiYvj.jpg" alt="개통" />
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div className="desc">[사진2 _ 런칭예정 특화 디바이스 (소셜로봇, 미러북)]</div>
    <br />
    <div className="descImage device">
      <div style={{ width: 40 + "%" }}>
        <img src="/images/press/2/ekfKiyJo.jpg" alt="소셜로봇" />
      </div>
      <div style={{ width: 30 + "px" }}></div>
      <div style={{ width: "calc(" + 60 + "% - " + 30 + "px" }}>
        <img src="/images/press/2/cCNJkkND.jpg" alt="미러북" />
      </div>
    </div>
  </div>
)

const press2 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com"
      date="2020년 8월 21일 즉시 배포 가능."
      pageInfo="(사진자료 메일 첨부함)"
      title="| 스마트폰, 이제는 카카오페이로 개통!"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press2
